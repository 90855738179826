@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Full HTML */
html, body {
  height: 100%;
  margin: 0;
}

* {
  font-family: "Roboto", sans-serif;
}

/* Navigation Bar */

.logo {
  font-size: 1.5rem;
  color: #fffce1;
  font-family: "Varela Round", sans-serif;
  margin-bottom: 5px;
}
.logo a:link, .logo a:visited{
  color:white
}

.app-navigation {
  margin: 0 auto;
  background-color: olive;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: white;
  align-items: center;
}

.surah-navigation {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-between;
  max-width: 1200px;
  padding-bottom: 20px;
}

.surah_name {
  font-size: 1.5rem;
  color: #fffce1;
  font-family: "Varela Round", sans-serif;
  margin-bottom: 5px;
}

.surah,
.juz,
.page,
.translations,
.resolution {
  margin-right: 10px;
}

.reciter {
  margin-left: 20px;
}

.showMoreNav {
  display: none;
  font-size: 2rem;
  top: 0;
  right: 0;
  position: absolute;
  margin-top: 20px;
  margin-right: 10px;
}

.config_options {
  margin-top:10px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  width:100%;
}

.mobile, .mobile-reciter{
  display:none;
}

.mobile-surah, .mobile-juz, .mobile-page, .mobile-translations, .mobile-resolution {
  margin-bottom: 10px;
}

.mobile-translations{
  max-width: 300px;
}

@media only screen and (max-width: 1025px) {
  .logo {
    font-size: 1.5rem;
  }

  .surah_name {
    font-size: 1.5rem;
  }
  .app-navigation {
    flex-direction: column;
  }
  .config_options {
    margin-top: 10px;
  }
  .config_options {
    justify-content: center;
    align-items: center;
  }
  .surah,
  .juz,
  .page,
  .translations {
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 453px) {
  .logo {
    font-size: 1.2rem;
  }

  .surah_name {
    font-size: 1.2rem;
  }

  .config_options {
    display: none;
  }

  .app-navigation {
    padding: 10px;
  }
  .translation-banner-top {
    background-color: olive;
    height: 5px;
    width: 100%;
  }

  .tooltip-content {
    padding: 15px;
    line-height: 22px;
    text-align: justify;
    text-justify: inter-word;
  }
  .tooltip {
    font-size: 1rem;
  }
  .showMoreNav {
    display: block;
  }
  .mobile{
    display:flex;
    flex-direction: column;;
  }

  .surah, .juz, .page, .translations, .resolution, .reciter{
    display:none;
  } 
  .mobile-reciter{
    display:block;
    padding: 10px;
  }

}

/* Image Box */

.container {
  position: relative;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.tooltip {
  position: absolute;
  color: black;
  background: white;
  border-radius: 5px;
  pointer-events: auto !important;
  /* pointer-events: none; */
  z-index: 1000;
  text-align: left;
  font-size: 100%;
  margin-left: 80px;
  margin-right: 80px;
  font-size: 1.3rem;
  box-shadow: 0 8px 16px 8px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.tooltip-content {
  padding: 30px;
  line-height: 30px;
  text-align: justify;
  text-justify: inter-word;
}

.translation-banner-top {
  background-color: olive;
  height: 10px;
  width: 100%;
}

.translation_meta {
  margin-left: 10px;
  font-style: italic;
}

.tooltip-header {
  font-weight: 800;
  color: #556b2f;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 500px) {
  .tooltip {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
}

@media only screen and (min-width: 888px) {
  .tooltip {
    width: 640px;
  }
}

/* Audio Player */

.audioPlayer {
  z-index: 5000;
  position: absolute;
}

.audioPlayer {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.audioBody {
  width: 80%;
  background-color: white;
  box-shadow: 0 8px 16px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  text-align: left;
  color: black;
  font-size: 100%;
  transition: 0.3s;
  padding: 10px;
}

.audio_container {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.collapse_audio {
  text-align: center;
  font-size: 2rem;
  cursor: pointer;
  color: olive;
}

.audio_meta {
  font-weight: 800;
}

.audioPlayer {
  display: flex;
}

.close {
  margin-top: -10px;
  cursor: pointer;
}

.audio_setting {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  align-items: center;
}

.audio_setting_player {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-icon {
  cursor: pointer;
}

@media only screen and (max-width: 500px) {
  .audioBody {
    width: 100%;
    padding: 5px;
  }
  .audio_setting {
    justify-content: space-between;
    flex-wrap: wrap;
    justify-content: center;
  }
  .reciter {
    margin-top: 10px;
  }
}

/* Previous Next Page */

.page-navigation {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
}

.ayah-navigation {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  margin-top: 10px;
}

/* Footer */

.footer {
  margin-top: 50px;
  background-color: olive;
  height: 100px;
  padding: 50px;
  
}

.footer-content {
  color: #fffce1;
}

.footer-content a:link, .footer-content a:visited{
  color:white;
}

#tooltipcontainer{
  display: none;
}


.disclaimer{
  max-width: 1200px;
  margin: 0 auto;
  min-height: calc(100vh - 120px);
  padding: 30px;
}

.imgLoader {
  min-height: calc(100vh - 120px);
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.disclaimer-content{
  text-align: left;
}


/* Loader */
.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: olive;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.imageLoadingText{
  font-style: italic;
  color:olive;
}

.home-container{
  text-align: left;
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
}


@media only screen and (max-width: 453px) {
  .config_options .page{
    display : none;
  }
}


#minimize-audio{
  font-size:1rem;
}

.body-wrapper{
  min-height: calc(100vh - 100px);
}

.ui.card{
  width: 200px !important;
  text-align: center;
}

.ui.card :hover{
 background-color: olive !important;
 cursor: pointer;
}

.ui.cards{
  justify-content: space-between;
}